import '@js/modules/skip-link-focus-fix.js'
import '@js/modules/polyfill'
import Promise from 'es6-promise-promise'
import modernizr from '@js/vendor/modernizr-3.6.0.min.js'
import throttle from 'lodash/throttle'
// import debounce from 'lodash/debounce'
import RM from '@js/vendor/RM'
import Util from '@js/vendor/Util'

import variables from '@js/variables'
import debug from '@js/modules/debug'

import Cookie from '@js/modules/cookie'
import Navigation from '@js/modules/navigation'
import PageTransition from '@js/modules/pageTransition'
import MouseFollower from '@js/modules/mouseFollower'
import Pointer from '@js/modules/pointer'
import * as base from '@js/modules/base'

window.Util      = Util
window.debug     = debug
window.throttle  = throttle
// window.debounce  = debounce
window.variables = variables

// TODO : brotli files


const SELECTORS = {
  // loaderProgress: '.site-ui__header',
  loaderCursorProgress: '.mouse-main__text--loading__bg',

  linkWords: '.link-words',
  linkLetters: '.link-letters',
  gallery_item: '.gallery-item, .blocks-gallery-item',
  form: '.wpcf7-form',

  blockReveal: '.blockReveal',
  scrollReveal: '.scrollReveal',
  sliceReveal: '.sliceReveal',

  parallax: '.item-parallax:not(.no-instance)',

  distorsionEffect: '.distorsionEffect',
  postslideshow: '.postslideshow',
  chambreslist : 'chambres-list-slider', // id
  commentslideshow: '.commentslist',

  saisonCalendar: '.saison-calendar',
  popper: '.popper'
}
const CLASSES = {
  loading  : 'loading',
  loaded   : 'loaded',
  animation: 'fade-in',

  scrollingUp  : 'scrollingUp',
  scrollingDown: 'scrollingDown',
  hasScroll    : 'hasScroll',

  pagetransition: 'pagetransition'
}

// Main App Object
const App = {

  ga_code: 'UA-34932884-1',
  cssTags : [],

  loadTotal: 0,
  loadProgress: 0,
  modulesToInit: {},

  el: {
    // html: document.documentElement,
    html: RM.Dom.html,
    // body: document.body,
    body: RM.Dom.body,
    // site: document.getElementById('site'),
    site: RM.G.id('site'),
    // main: document.getElementById('main')
    main: RM.G.id('main'),
    breadcrumbs: RM.G.id('breadcrumbs')
  },

  bounds: {
    window_w     : 0,
    window_h     : 0,
    siteOffsetTop: 0,
    mainOffsetTop: 0
  },

  env: {
    // isIE: navigator.userAgent.indexOf('MSIE ') > 0 || navigator.userAgent.match(/Trident.*rv\:11\./) !== null,
    isIE: RM.Snif.isIE,
    // isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null,
    isMobile: RM.Snif.isMobile,

    cssVariablesSupport: window.CSS && CSS.supports('color', 'var(--fake-var)'),
    webpSupport: Util.hasClass( document.documentElement, 'webp' ),
    intersectionObserverSupport: "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype,
    mutationObserverSupport: "MutationObserver" in window,
    idleCallbackSupport: "requestIdleCallback" in window
  },

  MathUtils: {
    // Equation of a line.
    lineEq: (y2, y1, x2, x1, currentVal) => {
      var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
      return m * currentVal + b;
    },
    // Linear Interpolation function.
    lerp: (a, b, n) => (1 - n) * a + n * b,
    // Normalisation function
    norm: (value, min, max) => {
      return (value - min) / (max - min)
    }
  },

  initialize() {
    const t = this
    Util.addClass(this.el.html, this.env.isIE ? 'isIE' : 'isNotIE' )
    Util.addClass(this.el.html, this.env.isMobile ? 'isMobile' : 'isNotMobile' )
    // Util.removeClass(this.el.html, 'no-js');

    // this.el.pageloaderProgress   = RM.Select.el(SELECTORS.loaderProgress)[0]
    this.el.cursorloaderProgress = RM.Select.el(SELECTORS.loaderCursorProgress)[0]

    this.registerServiceWorker()
    // this.lazyLoading()
    this.initScrollTopButton()
    // this.initBtnBook()
    this.initLogoLink()
    this.initializeQuicklink()
    this.initResizeEvents()
    this.initScrollEvents()

    this.Cookie         = new Cookie()
    this.Navigation     = new Navigation()
    // this.PageTransition = new PageTransition()
    this.MouseFollower  = new MouseFollower()
    this.Pointer        = new Pointer()

    // IE ? Add an alert
    // if ( this.isIE ) {
    //   const ie_p = document.createElement('p')
    //   Util.addClass(ie_p, 'browserupgrade')
    //   ie_p.innerHTML = 'You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/" target="_blank">upgrade your browser</a> to improve your experience and security.'
    //   this.body.appendChild(ie_p)
    // }

    this.copyright()

    this.init()
  },

  init() {
    const t = this

    // Reset
    this.modulesToInit = []
    this.loadTotal     = 0
    this.loadProgress  = 0
    this.el.cursorloaderProgress.style.transform = 'scaleY(0)'

    this.Lightbox          = null
    this.Gallery           = null
    this.Smooth            = null
    this.Parallax          = null
    this.SliceReveal       = null
    this.BlockReveal       = null
    this.ScrollReveal      = null
    this.Distorsion        = null
    this.PostSlideshow     = null
    this.ChambresList      = null
    this.SaisonCalendar    = null
    this.CommentsSlideshow = null

    this.el.html = document.documentElement;
    this.el.body = document.body;
    this.el.site = document.getElementById('site');
    this.el.main = document.getElementById('main');
    this.el.breadcrumbs = document.getElementById('breadcrumbs');

    const windowDim = Util.getWindowDimensions()
    this.bounds.window_w = windowDim.width
    this.bounds.window_h = windowDim.height
    this.bounds.siteOffsetTop = this.el.site.offsetTop
    this.bounds.mainOffsetTop = this.bounds.siteOffsetTop + this.el.main.offsetTop

    // Init the page
    this.Pointer.init()
    this.initQuicklink()
    this.initAncreButton()
    // this.initBtnContact()
    this.MouseFollower.setDisabled()
    this.MouseFollower.initPageLinks()
    this.modulesToInit['Lightbox'] = 1
    this.modulesToInit['Smooth']   = 1

    // All pages
    import('@js/modules/lightbox').then(function (module) {
      const Lightbox = module.default
      t.Lightbox = new Lightbox()
      t.Lightbox.initialize()
    })
    import('@js/modules/smoothScroll').then(function (module) {
      const Smooth = module.default
      t.Smooth = new Smooth()
      t.Smooth.initialize()
    })

    // All pages > init scripts if there is at least one
    if ( RM.Select.el(SELECTORS.linkWords).length || RM.Select.el(SELECTORS.linkLetters).length ) {
      import('@js/modules/link').then(function (module) {
        const Link = module.default
        t.Link = new Link()
      })
    }
    if ( RM.Select.el(SELECTORS.form).length ) {
      import('@js/modules/form').then(function (module) {
        const Form = module.default
        t.Form = new Form()
      })
    }
    // if ( RM.Select.el(SELECTORS.gallery_item).length ) {
    //   this.modulesToInit['Gallery'] = 1
    //   import('@js/modules/gallery').then(function (module) {
    //     const Gallery = module.default
    //     t.Gallery = new Gallery()
    //     t.Gallery.initialize()
    //   })
    // }
    if ( RM.Select.query(SELECTORS.parallax) ) {
      this.modulesToInit['Parallax'] = 1
      import('@js/modules/parallax').then(function (module) {
        const Parallax = module.default
        t.Parallax = new Parallax()
        t.Parallax.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.sliceReveal).length ) {
      this.modulesToInit['SliceReveal'] = 1
      import('@js/modules/sliceReveal').then(function (module) {
        const SliceRevealer = module.default
        t.SliceReveal = new SliceRevealer()
        t.SliceReveal.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.blockReveal).length ) {
      this.modulesToInit['BlockReveal'] = 1
      import('@js/modules/blockReveal').then(function (module) {
        const BlockRevealer = module.default
        t.BlockReveal = new BlockRevealer()
        t.BlockReveal.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.scrollReveal).length ) {
      this.modulesToInit['ScrollReveal'] = 1
      import('@js/modules/scrollReveal').then(function (module) {
        const ScrollRevealer = module.default
        t.ScrollReveal = new ScrollRevealer()
        t.ScrollReveal.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.distorsionEffect).length ) {
      this.modulesToInit['Distorsion'] = 1
      import('@js/modules/distorsionEffect').then(function (module) {
        const Distorsion = module.default
        t.Distorsion = new Distorsion()
        t.Distorsion.initialize()
      })
    }
    // if ( !this.isIE && this.body.querySelector(SELECTORS.postslideshow) ) {
    if ( RM.Select.el(SELECTORS.postslideshow).length ) {
      this.modulesToInit['PostSlideshow'] = 1
      import('@js/modules/postslideshow').then(function (module) {
        const PostSlideshow = module.default
        t.PostSlideshow = new PostSlideshow()
        t.PostSlideshow.initialize()
      })
    }
    if ( RM.G.id(SELECTORS.chambreslist) ) {
      this.modulesToInit['ChambresList'] = 1
      import('@js/modules/chambreslist').then(function (module) {
        const ChambresList = module.default
        t.ChambresList = new ChambresList()
        t.ChambresList.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.commentslideshow).length ) {
      this.modulesToInit['CommentsSlideshow'] = 1
      import('@js/modules/commentslideshow').then(function (module) {
        const CommentsSlideshow = module.default
        t.CommentsSlideshow = new CommentsSlideshow()
        t.CommentsSlideshow.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.saisonCalendar).length ) {
      this.modulesToInit['SaisonCalendar'] = 1
      import('@js/modules/saisonCalendar').then(function (module) {
        const SaisonCalendar = module.default
        t.SaisonCalendar = new SaisonCalendar()
        t.SaisonCalendar.initialize()
      })
    }
    if ( RM.Select.el(SELECTORS.popper).length ) {
      import('@js/modules/popperElement').then(function (module) {
        const PopperElement = module.default
        t.PopperElement = new PopperElement()
        t.PopperElement.initialize()
      })
    }

    this.loadTotal += Object.keys(this.modulesToInit).length

    this.ifrInterval = null
    RM.L(window, 'a', 'load', e => {
      this.ifrInterval = setInterval(this.getIframe, 100)
    })


    // Page Transition "simple"
    let current_url = window.location.href;
    if (current_url.indexOf('#') !== -1) {
      current_url = current_url.substring( 0, current_url.indexOf('#') );
    }
    // debug('current url is ' + current_url);

    [...document.querySelectorAll('a')].forEach((a) => {

      if (
          a.classList.contains('no-ajax') ||
          a.classList.contains('parvbox') ||
          a.classList.contains('js_gallery') ||
          a.classList.contains('lightbox') ||
          a.classList.contains('scrollTop') ||
          a.classList.contains('cn-button') ||
          Util.hasClass(a.parentNode, 'lang-item')
      ) {
      } else {
        const url = a.getAttribute('href');
        let url_withtout_hash = url;
        let url_has_hash = url.indexOf('#') !== -1;
        if (url_has_hash) {
          url_withtout_hash = url.substring( 0, url.indexOf('#') );
        }

        if (url && url.indexOf(site_url) === 0 && url.indexOf('.zip') === -1 && url.indexOf('/wp-admin') === -1)
        {
          // debug('link url is', url, url_withtout_hash);
          // debug(url_withtout_hash === current_url ? 'same url' : 'different url');

          if (!url_has_hash || (url_has_hash && url_withtout_hash !== current_url)) {
            a.addEventListener('click', (e) => {
              // If Command (macOS) or Ctrl (Windows) key pressed, stop processing
              if (e.metaKey || e.ctrlKey || a.getAttribute('target')) {
                // return w.open(element.src, '_blank');
                return;
              }

              e.preventDefault()

              Util.addClass(App.el.html, CLASSES.pagetransition)
              Util.removeClass(App.el.body, CLASSES.loaded)
              Util.addClass(App.el.body, CLASSES.loading)

              App.Smooth.off()

              const delay = new RM.Delay(_ => {
                window.location = url;
              }, variables.transition) // variables.transition_slow
              delay.run()

            });
          }
        }
      }
    });
  },

  getScrollY () {
    return window.pageYOffset || this.el.html.scrollTop;
  },

  // Init Resize & Orientation (ro) Events
  initResizeEvents () {
    const t = this

    RM.BM(this, ['resize'])
    this.ro = new RM.RO({
      cb: this.resize,
      throttleDelay: 200
    })
    this.ro.on()

    // window.addEventListener(
    //   'resize',
    //   debounce((e) => {
    //     t.bounds.window_w      = window.innerWidth
    //     t.bounds.window_h      = window.innerHeight
    //     t.bounds.siteOffsetTop = t.el.site.offsetTop
    //     t.bounds.mainOffsetTop = t.bounds.siteOffsetTop + t.el.main.offsetTop
    //     t.btnContact.height = t.btnContact.el.clientHeight
    //   }, 200)
    // )
  },
  resize (e) {
    // debug(e)
    this.bounds.window_w      = window.innerWidth
    this.bounds.window_h      = window.innerHeight
    this.bounds.siteOffsetTop = this.el.site.offsetTop
    this.bounds.mainOffsetTop = this.bounds.siteOffsetTop + this.el.main.offsetTop
    // this.btnContact.height    = this.btnContact.el.clientHeight

    this.ifrInterval = setInterval(this.getIframe, 100)
  },

  // Init Scroll Events
  // => Scroll Top button
  // => Contact button
  initScrollEvents () {
    const t = this
    this.sy              = 0
    this.scrollTreshold  = 20
    this.scrollDirection = false

    RM.L(window, 'a', 'scroll', throttle((e) => {
      var sy = this.getScrollY()

      // Scroll Direction
      // if ( sy > this.sy + this.scrollTreshold ) {
      //   this.scrollDirection = 'down'
      //   Util.removeClass(this.el.body, CLASSES.scrollingUp)
      //   Util.addClass(this.el.body, CLASSES.scrollingDown)
      //   this.sy = sy
      // } else if ( sy < this.sy - this.scrollTreshold ) {
      //   this.scrollDirection = 'up'
      //   Util.removeClass(this.el.body, CLASSES.scrollingDown)
      //   Util.addClass(this.el.body, CLASSES.scrollingUp)
      //   this.sy = sy
      // }

      // Has Scroll ?
      if ( sy > this.bounds.mainOffsetTop ) {
        Util.addClass(this.el.body, CLASSES.hasScroll)
        Util.removeClass(this.el.scrollTop, 'invisible')
      } else {
        Util.removeClass(this.el.body, CLASSES.hasScroll)
        Util.addClass(this.el.scrollTop, 'invisible')
      }

      // ScrollTop Button
      // if ( sy >= (this.bounds.window_h / 3) ) {
      //   Util.removeClass(this.el.scrollTop, 'invisible')
      // } else {
      //   Util.addClass(this.el.scrollTop, 'invisible')
      // }

      // Pointer
      this.Pointer.run()

      // Btn Contact
      // this.moveBtnContact()
    }, 60))

    // window.addEventListener(
    //   'scroll',
    //   throttle((e) => {
    //     var sy = t.getScrollY()

    //     // Scroll Direction
    //     if ( sy > t.sy + t.scrollTreshold ) {
    //       this.scrollDirection = 'down'
    //       Util.removeClass(t.body, CLASSES.scrollingUp)
    //       Util.addClass(t.body, CLASSES.scrollingDown)
    //       t.sy = sy
    //     } else if ( sy < t.sy - t.scrollTreshold ) {
    //       this.scrollDirection = 'up'
    //       Util.removeClass(t.body, CLASSES.scrollingDown)
    //       Util.addClass(t.body, CLASSES.scrollingUp)
    //       t.sy = sy
    //     }

    //     // Has Scroll ?
    //     if ( sy > t.bounds.mainOffsetTop ) {
    //       Util.addClass(t.body, CLASSES.hasScroll)
    //     } else {
    //       Util.removeClass(t.body, CLASSES.hasScroll)
    //     }

    //     // ScrollTop Button
    //     if ( sy >= (t.bounds.window_h / 3) ) {
    //       Util.removeClass(t.el.scrollTop, 'invisible')
    //     } else {
    //       Util.addClass(t.el.scrollTop, 'invisible')
    //     }

    //     // Btn Contact
    //     t.moveBtnContact()
    //   }, 60)
    // )
  },

  // initBtnBook () {
  //   this.el.btnBook = RM.G.id('btn-book')
  //   if ( ! this.el.btnBook ) {
  //     return
  //   }
  //   this.el.availProCalendar = RM.G.id('availpro-calendar-host')

  //   RM.L(this.el.btnBook, 'a', 'click', (e) => {
  //     e.preventDefault()

  //     debug(e)
  //     debug(this)

  //     if ( ! this.el.availProCalendar ) {
  //       this.el.availProCalendar = RM.G.id('availpro-calendar-host')
  //     }
  //     debug(this.el.availProCalendar)

  //     Util.toggleClass(this.el.availProCalendar, 'isOpen', !Util.hasClass(this.el.availProCalendar, 'isOpen'))
  //   })
  // },

  // Init Btn Contact
  // initBtnContact () {
  //   this.btnContact = {
  //     active: false,
  //     el    : RM.G.id('btn-contact-wrapper'),
  //     pageHeaderEl: RM.Select.el('.page__header')[0],
  //     height      : 0
  //   }
  //   this.btnContact.height = this.btnContact.el.clientHeight
  //   this.moveBtnContact()
  // },

  // // Move Btn Contact
  // moveBtnContact () {
  //   if ( ! this.btnContact.active || !this.btnContact.pageHeaderEl ) {
  //     return
  //   }

  //   let pageHeaderBottom = this.btnContact.pageHeaderEl.getBoundingClientRect().bottom
  //   let h = this.btnContact.height
  //   if ( pageHeaderBottom < this.bounds.window_h ) {
  //     h = h - Math.abs(pageHeaderBottom - this.bounds.window_h)
  //   }
  //   if (h < 0) {
  //     h = 0
  //   }

  //   this.btnContact.el.style.transform = 'translateY(' + h + 'px)'
  // },

  // Init Scroll Top Button
  initScrollTopButton () {
    this.el.scrollTop = RM.G.id('scrollTop')
    // RM.L(this.el.scrollTop, 'a', 'click', () => Util.scrollToTop())
    // RM.L(this.el.scrollTop, 'a', 'click', () => RM.ScrollToTop({
    //   h: 0,
    //   // cb: afterTop
    // }))

    RM.L(this.el.scrollTop, 'a', 'click', () => Util.scrollToTop())

    // const onScrollTopClick = e => {
    //   window.scroll({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    //   })
    //   e.preventDefault()
    // }
    // this.scrollTop.addEventListener('click', onScrollTopClick)
    // RM.L(this.scrollTop, 'a', 'click', onScrollTopClick)
  },

  // Init link with #idOfAnElement
  // => scroll to this element
  initAncreButton () {
    this.el.linksAncre = Array.from( document.querySelectorAll('a[href^="#"]:not(.tabs__link):not(.scrollTop):not(.screen-reader-text):not(.screen-reader-shortcut)') )

    // CallBack Function
    const ancre_click = e => {
      const target = document.querySelector( e.target.getAttribute('href') )

      if ( target ) {
        e.preventDefault()

        // target.scrollIntoView({
        //   behavior: 'smooth'
        // })
        // Util.scrollTo( target.offsetTop )
        RM.ScrollTo({
          dest: target.offsetTop,
          d: variables.transition,
          e: 'o3',
          // cb: afterTop
        })
      }
    }

    const initAncreClickForElem = item => {
      // item.addEventListener('click', ancre_click)
      RM.L(item, 'a', 'click', ancre_click)
    }

    // Add Event Listener to links
    this.el.linksAncre.forEach( link => initAncreClickForElem(link) )
  },

  // Init Logo Link
  initLogoLink () {
    const logo = RM.G.id('site-logo')
    const coiffe = RM.G.id('site-logo-coiffe')
    // logo.addEventListener('click', () => {
    //   coiffe.click()
    // })
    RM.L(logo, 'a', 'click', () => {
      coiffe.click()
    })
  },

  // Init Customer Alliance Iframe positionning
  getIframe () {
    const widget  = RM.Select.query('iframe[name="ca-review-widget"]')
    const tooltip = RM.Select.query('iframe[name="ca-review-tooltip"]')

    if (widget) {
      clearInterval(this.ifrInterval)

      setTimeout(() => {
        widget.style.removeProperty('display')
        widget.style.removeProperty('position')
        widget.style.removeProperty('margin')
        widget.style.removeProperty('top')
        widget.style.removeProperty('transform')
        // widget.style.removeProperty('left')
        // widget.style.removeProperty('width')
        tooltip.style.removeProperty('display')
        tooltip.style.removeProperty('top')
        tooltip.style.removeProperty('transform')
        // tooltip.remove()
      }, 100)
    }
  },

  // Init Quicklink
  // => prefetch in-viewport links during idle time
  // https://github.com/GoogleChromeLabs/quicklink
  initializeQuicklink () {
    if (! this.env.intersectionObserverSupport ) {
      return
    }
    const t = this
    RM.L(window, 'a', 'load', () => {
      import('quicklink').then(function (module) {
        t.quicklink = module.default
      })
    })
  },
  initQuicklink () {
    if (! this.quicklink ) {
      return
    }
    this.quicklink({
      // origins: [
      //   site_url.replace(/(^\w+:|^)\/\//, '')
      // ], // default to : [location.hostname]
      ignores: [
        /\/api\/?/,
        /\/wp-admin\/?/,
        /\/wp-login\/?/,
        uri => uri.includes('.zip'),
        uri => uri.includes('#'),
        (uri, elem) => elem.hasAttribute('noprefetch')
      ]
    })
  },

  // lazyLoading() {
  //     this.LazyLoading = new Blazy({
  //         selector: '.lazyload',
  //         successClass: 'loaded'
  //     })
  // },

  displayLoadProgress () {
    let progress = 0,
        progress_base = 0
    if ( this.loadTotal > 0 ) {
      progress_base = this.loadProgress / this.loadTotal
      progress = progress_base * 100
    }

    // this.pageloaderTextPercent.innerHTML = Math.round( progress ) + '%'
    // this.el.pageloaderProgress.style.transform = 'scaleY(' + progress_base + ')'
    this.el.cursorloaderProgress.style.transform = 'scaleY(' + progress_base + ')'
  },

  checkAppLoaded () {
    const t = this
    let isLoaded = true

    // console.log('---START checkloaded---')

    // if (isLoaded && this.modulesToInit['PageLoader']) {
    //   isLoaded = typeof this.PageLoader != 'undefined' && this.PageLoader.isLoaded
    // }
    if (isLoaded && this.modulesToInit['Gallery']) {
      // isLoaded = typeof this.Gallery != 'undefined' && this.Gallery.isLoaded
      isLoaded = this.Gallery != null && this.Gallery.isLoaded
      // console.log('Gallery is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['Lightbox']) {
      // isLoaded = typeof this.Lightbox != 'undefined' && this.Lightbox.isLoaded
      isLoaded = this.Lightbox != null && this.Lightbox.isLoaded
      // console.log('Lightbox is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['Smooth']) {
      // isLoaded = typeof this.Smooth != 'undefined' && this.Smooth.isLoaded
      isLoaded = this.Smooth != null && this.Smooth.isLoaded
      // console.log('Smooth is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['Parallax']) {
      // isLoaded = typeof this.Parallax != 'undefined' && this.Parallax.isLoaded
      isLoaded = this.Parallax != null && this.Parallax.isLoaded
      // console.log('Parallax is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['ScrollReveal']) {
      // isLoaded = typeof this.ScrollReveal != 'undefined' && this.ScrollReveal.isLoaded
      isLoaded = this.ScrollReveal != null && this.ScrollReveal.isLoaded
      // console.log('ScrollReveal is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['BlockReveal']) {
      // isLoaded = typeof this.BlockReveal != 'undefined' && this.BlockReveal.isLoaded
      isLoaded = this.BlockReveal != null && this.BlockReveal.isLoaded
      // console.log('BlockReveal is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['SliceReveal']) {
      // isLoaded = typeof this.SliceReveal != 'undefined' && this.SliceReveal.isLoaded
      isLoaded = this.SliceReveal != null && this.SliceReveal.isLoaded
      // console.log('SliceReveal is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['Distorsion']) {
      // isLoaded = typeof this.Distorsion != 'undefined' && this.Distorsion.isLoaded
      isLoaded = this.Distorsion != null && this.Distorsion.isLoaded
      // console.log('Distorsion is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['PostSlideshow']) {
      // isLoaded = typeof this.PostSlideshow != 'undefined' && this.PostSlideshow.isLoaded
      isLoaded = this.PostSlideshow != null && this.PostSlideshow.isLoaded
      // console.log('PostSlideshow is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['ChambresList']) {
      // isLoaded = typeof this.ChambresList != 'undefined' && this.ChambresList.isLoaded
      isLoaded = this.ChambresList != null && this.ChambresList.isLoaded
      // console.log('ChambresList is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['SaisonCalendar']) {
      // isLoaded = typeof this.SaisonCalendar != 'undefined' && this.SaisonCalendar.isLoaded
      isLoaded = this.SaisonCalendar != null && this.SaisonCalendar.isLoaded
      // console.log('SaisonCalendar is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }
    if (isLoaded && this.modulesToInit['CommentsSlideshow']) {
      // isLoaded = typeof this.CommentsSlideshow != 'undefined' && this.CommentsSlideshow.isLoaded
      isLoaded = this.CommentsSlideshow != null && this.CommentsSlideshow.isLoaded
      // console.log('CommentsSlideshow is ' + (isLoaded ? 'loaded' : 'not loaded'))
    }

    // console.log('---END checkloaded--- ' + (isLoaded ? 'loaded' : 'not loaded'))

    this.displayLoadProgress()

    if ( isLoaded ) {
      // debug('page is Loaded')
      Util.removeClass(this.el.body, CLASSES.loading)
      Util.addClass(this.el.main, CLASSES.animation)

      // console.time('loaded')
      const delay = new RM.Delay(_ => {
        Util.addClass(t.el.body,CLASSES.loaded)
        // console.timeEnd('loaded')
        // RM.G.id('pageloader').remove()
        // t.btnContact.active = true

        if ( t.modulesToInit['ScrollReveal'] ) t.ScrollReveal.init()
        if ( t.modulesToInit['BlockReveal'] ) t.BlockReveal.init()
        if ( t.modulesToInit['SliceReveal'] ) t.SliceReveal.init()
        if ( t.modulesToInit['Distorsion'] ) t.Distorsion.init()
        if ( t.modulesToInit['Parallax'] ) t.Parallax.init()
        if ( t.modulesToInit['PostSlideshow'] ) t.PostSlideshow.init()
        if ( t.modulesToInit['ChambresList'] ) t.ChambresList.init()
        if ( t.modulesToInit['SaisonCalendar'] ) t.SaisonCalendar.init()
        if ( t.modulesToInit['CommentsSlideshow'] ) t.CommentsSlideshow.init()
        if ( t.modulesToInit['Smooth'] ) t.Smooth.init()
        new base.PositionFull()
        new base.Tabs()
        new base.Toggles()
      }, variables.transition_slow)
      delay.run()
    }
  },

  // check for SW support and register
  registerServiceWorker () {
    if ('serviceWorker' in navigator) {
      const t = this
      // const scope = '/';
      const scope = '/hotel-deybach/'

      // Delay registration until after the page has loaded, to ensure that our
      // precaching requests don't degrade the first visit experience.
      // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
      window.addEventListener('load', function () {
        // Your service-worker.js *must* be located at the top-level directory relative to your site.
        // It won't be able to control pages unless it's located at the same level or higher than them.
        // See https://github.com/slightlyoff/ServiceWorker/issues/468
        /*navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
          debug('Service Worker registered successfully.')

          // updatefound is fired if service-worker.js changes.
          reg.onupdatefound = function() {
            // The updatefound event implies that reg.installing is set; see
            // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
            var installingWorker = reg.installing

            installingWorker.onstatechange = function() {
              switch (installingWorker.state) {
                case 'installed':
                  if (navigator.serviceWorker.controller) {
                      // At this point, the old content will have been purged and the fresh content will
                      // have been added to the cache.
                      // It's the perfect time to display a "New content is available; please refresh."
                      // message in the page's interface.
                      debug('New or updated content is available.')
                  } else {
                      // At this point, everything has been precached.
                      // It's the perfect time to display a "Content is cached for offline use." message.
                      debug('Content is now available offline!')
                  }
                  break;

                case 'redundant':
                  console.error('The installing service worker became redundant.')
                  break;
              }
            };
          };
        }).catch(function(e) {
            console.error('Error during service worker registration:', e)
        })*/

        // Background Sync
        // navigator.serviceWorker.ready.then((swRegistration) => {
        //   return swRegistration.sync.register('event1')
        // })
      })
    }
  },

  copyright () {
    const style = [
      'color: #fff',
      'background: #ffc308',
      'padding: 4px 8px',
      'border-left: 5px solid #282828',
      'border-right: 5px solid #282828'
    ].join(';')

    debug('%cA creation by Boite à Oeufs 🇫🇷', style)
    debug('%c> Site : https://www.boite-a-oeufs.com', style)
    debug('%c> Twitter : https://twitter.com/BoiteOeufs', style)
    debug('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)

    debug('%c> Design by Julian Camaret', style)
    debug('%c> Twitter : https://twitter.com/Jus_motion', style)

    debug('%cDevelopment by Romain Murschel', style)
    debug('%c> Site : https://romainmurschel.fr', style)
    debug('%c> Twitter : https://twitter.com/balthorg', style)
  }
}
window.App = App

// Kick shit off
App.initialize()
