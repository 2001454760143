const settings = {
    brand_primary  : '#fc6767',
    brand_secondary: '#43a9a3',
    gray_lighter   : '#eceeef',
    gray_lightest  : '#f7f7f9',
    white          : '#fff',
    ease           : [.165, .84, .44, 1],
    transition     : 400,
    transition_fast: 250,
    transition_slow: 800,
    transition__inS     : .400,
    transition_fast__inS: .250,
    transition_slow__inS: .800,
}

export default settings