const SELECTORS = {
    cookieNotice: 'cookie-notice',
    cnContainer : '.cookie-notice-container',
    cnAccept    : 'cn-accept-cookie'
}


export default class Cookie {
    constructor () {
        this.cookieNotice = RM.G.id(SELECTORS.cookieNotice)
        this.cookie_img = '<svg><use xlink:href="#icon-cookie"></use></svg>'

        if (this.cookieNotice) {
            const t = this

            if ( Util.hasClass(RM.Dom.body, 'cookies-accepted') ) {
                // window.addEventListener('load', (e) => {
                //   this.cookieNotice.remove();
                // });
                return;
            }

            this.cnContainer = RM.Select.el(SELECTORS.cnContainer, this.cookieNotice)[0]
            this.cnAccept    = RM.G.id(SELECTORS.cnAccept)
            // this.cnContainer.querySelector('.cookie-notice-img').innerHTML = this.cookie_img
            RM.Select.el('.cookie-notice-img', this.cnContainer)[0].innerHTML = this.cookie_img

            import('animejs').then(function (obj) {
                t.anime = obj.default
                t.init()
            })
        }
    }

    init () {
        const t = this
        RM.L(this.cnAccept, 'a', 'click', (e) => {
        // this.cnAccept.addEventListener('click', (e) => {
            var cookieAnime = this.anime.timeline({
                duration: variables.transition,
                easing: variables.ease
            })
            cookieAnime
                .add({
                    targets: this.cnContainer,
                    opacity: 0,
                })
                .add({
                    targets: this.cookieNotice,
                    scaleX: 0,
                    x: ['-50%', '-25%'],
                    offset: '-=' + (variables.transition / 2),
                    begin: function(anim) {
                        t.cookieNotice.style.transformOrigin = 'right'
                        t.cookieNotice.style.transitionDelay = '0s'
                    }
                })

            cookieAnime.finished.then(() => {
                this.cookieNotice.remove()
            })
        })
    }
}
