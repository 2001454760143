const CLASSES = {
    view: 'cursor-view'
}

const SELECTORS = {
    custom   : 'custom-cursor',
    mouse    : 'mouse-main',
    // follower : 'mouse-follower',
    // pageLinks: 'a[href], button, .scrollTop, #site-logo, .swiper-pagination-bullet',
    view: '.cursor-view'
}

var CURSOR = {
    followerWidth: 40,
    cursors      : null,
    main         : {
        el       : null,
        slowRatio: 1,
        xp       : 0,
        yp       : 0
    },
    // follower     : {
    //     el       : null,
    //     slowRatio: 10,
    //     xp       : 0,
    //     yp       : 0
    // },

    isVisible    : false,
    isEnabled    : false,
    isView       : false,
    isDisabled   : false,
    isMousedown  : false,

    mouseX       : 0,
    mouseY       : 0,
    minX         : 0,
    minY         : 0,
    limitX       : 0,
    limitY       : 0
}

export default class MouseFollower {

    constructor () {
        CURSOR.cursors     = RM.G.id(SELECTORS.custom)
        CURSOR.main.el     = RM.G.id(SELECTORS.mouse)
        // CURSOR.follower.el = RM.G.id(SELECTORS.follower)

        if (CURSOR.cursors) {
            this.init()
        }
    }

    init () {
        // this.enabledSlowRatio = 1.1
        // this.baseSlowRatio = CURSOR.slowRatio

        CURSOR.minX   = CURSOR.followerWidth / 2
        CURSOR.minY   = CURSOR.followerWidth / 2
        CURSOR.limitX = window.innerWidth - ( CURSOR.followerWidth / 2 )
        CURSOR.limitY = document.innerHeight - ( CURSOR.followerWidth / 2 )

        // window.addEventListener('resize', debounce( e => {
        //    CURSOR.limitX = window.innerWidth - ( CURSOR.followerWidth / 2 )
        //    CURSOR.limitY = document.innerHeight - ( CURSOR.followerWidth / 2 )
        // }, 200 ))
        const ro = new RM.RO({
            // cb: this.resize,
            cb: (e) => {
                CURSOR.limitX = window.innerWidth - ( CURSOR.followerWidth / 2 )
                CURSOR.limitY = document.innerHeight - ( CURSOR.followerWidth / 2 )
            },
            throttleDelay: 200
        })
        ro.on()

        RM.L(window, 'a', 'mousemove', throttle( e => {
            // CURSOR.mouseX = e.pageX
            // CURSOR.mouseY = e.pageY
            CURSOR.mouseX = e.clientX
            CURSOR.mouseY = e.clientY

            // debug(CURSOR.mouseX, CURSOR.mouseY)

            if ( e.pageX < CURSOR.minX || e.pageX > CURSOR.limitX || e.pageY < CURSOR.minY || e.pageY > CURSOR.limitY ) {
                CURSOR.isVisible = false
            } else {
                CURSOR.isVisible = true
            }
        }, 30 ))

        // window.addEventListener('mousemove', throttle( e => {
        //     // CURSOR.mouseX = e.pageX
        //     // CURSOR.mouseY = e.pageY
        //     CURSOR.mouseX = e.clientX
        //     CURSOR.mouseY = e.clientY

        //     // debug(CURSOR.mouseX, CURSOR.mouseY)

        //     if ( e.pageX < CURSOR.minX || e.pageX > CURSOR.limitX || e.pageY < CURSOR.minY || e.pageY > CURSOR.limitY ) {
        //         CURSOR.isVisible = false
        //     } else {
        //         CURSOR.isVisible = true
        //     }
        // }, 30 ))

        RM.L(window, 'a', 'mousedown', e => {
            CURSOR.isMousedown = true
        })
        // window.addEventListener('mousedown', e => {
        //     CURSOR.isMousedown = true
        // })
        RM.L(window, 'a', 'mouseup', e => {
            CURSOR.isMousedown = false
        })
        // window.addEventListener('mouseup', e => {
        //     CURSOR.isMousedown = false
        // })

        moveFollower ()
    }

    initPageLinks () {
        // this.pageLinks = Array.from( document.querySelectorAll(SELECTORS.pageLinks) )
        this.pageLinks = Array.from( document.querySelectorAll(SELECTORS.view) )

        // Check all links
        // when hover -> disable the follower
        for (let i = 0; i < this.pageLinks.length; i += 1) {
            const l = this.pageLinks[i]
            const onMouseEnter = e => {
                // if ( Util.hasClass( e.target, CLASSES.view ) ) {
                    CURSOR.isView    = true
                    CURSOR.isEnabled = false
                // } else {
                //     CURSOR.isView    = false
                //     CURSOR.isEnabled = true
                // }
            }
            const onMouseLeave = e => {
                CURSOR.isDisabled = CURSOR.isEnabled = CURSOR.isView = false
            }
            
            RM.L(l, 'r', 'mouseenter', onMouseEnter)
            RM.L(l, 'r', 'mouseleave', onMouseLeave)

            // l.addEventListener('mouseenter', onMouseEnter)
            // l.addEventListener('mouseleave', onMouseLeave)
            RM.L(l, 'a', 'mouseenter', onMouseEnter)
            RM.L(l, 'a', 'mouseleave', onMouseLeave)
        }
    }

    // resize (e) {
    //     CURSOR.limitX = window.innerWidth - ( CURSOR.followerWidth / 2 )
    //     CURSOR.limitY = document.innerHeight - ( CURSOR.followerWidth / 2 )
    // }

    // setEnabled (state) {
    //     if (state) {
    //         CURSOR.isEnabled = true
    //         CURSOR.slowRatio = this.enabledSlowRatio
    //     } else {
    //         CURSOR.isEnabled = false
    //         CURSOR.slowRatio = this.baseSlowRatio
    //     }
    // }

    // setDisabled (state) {
    //     if (state) {
    //         CURSOR.isDisabled = true
    //     } else {
    //         CURSOR.isDisabled = false
    //     }
    // }

    setDisabled () {
        CURSOR.isDisabled = CURSOR.isEnabled = CURSOR.isView = false
    }

    // getScrollY () {
    //     return window.pageYOffset || document.documentElement.scrollTop;
    // }
}

function moveFollower () {

    // Define the new position of the Mouse main
    CURSOR.main.xp += (CURSOR.mouseX - CURSOR.main.xp) / CURSOR.main.slowRatio
    CURSOR.main.yp += (CURSOR.mouseY - CURSOR.main.yp) / CURSOR.main.slowRatio

    // // Define the new position of the Mouse follower
    // CURSOR.follower.xp += (CURSOR.mouseX - CURSOR.follower.xp) / CURSOR.follower.slowRatio
    // CURSOR.follower.yp += (CURSOR.mouseY - CURSOR.follower.yp) / CURSOR.follower.slowRatio

    // Defines the state of the follower
    if ( CURSOR.isMousedown ) {
        // CURSOR.cursors.classList.add('isMousedown')
        Util.addClass(CURSOR.cursors, 'isMousedown')
    } else {
        Util.removeClass(CURSOR.cursors, 'isMousedown')
    }

    if ( CURSOR.isDisabled ) {
        Util.addClass(CURSOR.cursors, 'isDisabled')
    } else {
        Util.removeClass(CURSOR.cursors, 'isDisabled')

        if ( CURSOR.isEnabled ) {
            Util.addClass(CURSOR.cursors, 'isEnabled')
        } else {
            Util.removeClass(CURSOR.cursors, 'isEnabled')
        }

        if ( CURSOR.isView ) {
            Util.addClass(CURSOR.cursors, 'isView')
        } else {
            Util.removeClass(CURSOR.cursors, 'isView')
        }

        if ( CURSOR.isVisible ) {
            Util.addClass(CURSOR.cursors, 'isVisible')

            // Move the Mouse & Follower
            CURSOR.main.el.style.transform     = 'translate3d(' + CURSOR.main.xp + 'px,' + CURSOR.main.yp + 'px,0)'
            // CURSOR.follower.el.style.transform = 'translate3d(' + CURSOR.follower.xp + 'px,' + CURSOR.follower.yp + 'px,0)'
        } else {
            Util.removeClass(CURSOR.cursors, 'isVisible')
        }
    }

    requestAnimationFrame(moveFollower)
}