const SELECTORS = {
    pointer: '_p'
}

export default class Pointer {

    constructor () {
        this.isRunning = false
        this.timer = new RM.Delay(_ => {
            this.isRunning = false
            this.pointer('all')
        }, 100)
    }

    init () {
        this.el  = RM.G.class(SELECTORS.pointer)
        this.elL = this.el.length
    }

    run () {
        this.timer.stop()
        if ( !this.isRunning ) {
            this.isRunning = true
            this.pointer('none')
        }
        this.timer.run()
    }

    pointer (state) {
        // debug('set pointerEvents to : ' + state)
        // debug(this.el)
        for (let i = 0; i < this.elL; i++) {
            if (this.el[i]) {
                this.el[i].style.pointerEvents = state
            }
        }
    }

}
